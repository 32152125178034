import { setStyle, GAME_ID, Style } from '@config/game.js'
import { watchEffect, ref, computed, watch } from 'vue'
import { isObjEmpty } from '@helpers/utils.js'
import { themeStore } from '@stores'
import Color from 'color'
import {
  DEFAULT_SECONDARY,
  DEFAULT_SUCCESS,
  DEFAULT_WARNING,
  DEFAULT_PRIMARY,
  DEFAULT_ACTIVE,
  DEFAULT_DANGER,
  DEFAULT_LIGHT,
  LIGHT_TEXT,
  DARK_TEXT,
} from '@config/colorsTheme.js'

const light = ref(DEFAULT_LIGHT)
const success = ref(DEFAULT_SUCCESS)
const warning = ref(DEFAULT_WARNING)
const danger = ref(DEFAULT_DANGER)

const lightTemp = ref(DEFAULT_LIGHT)

const WHITE_PANEL_REF = '#f2f2f2'
const WHITE_THRESHOLD = 20

const { getTheme } = themeStore()
export default function () {
  const primaryButtonColor = computed(
    () => Style.value.primaryButtonColor || DEFAULT_PRIMARY
  )
  const primaryButtonColorHover = computed(
    () =>
      Style.value.primaryButtonColorHover ||
      _hoverColor(primaryButtonColor.value)
  )
  const secondaryButtonColor = computed(
    () => Style.value.secondaryButtonColor || DEFAULT_SECONDARY
  )
  const secondaryButtonColorHover = computed(
    () =>
      Style.value.secondaryButtonColorHover ||
      _hoverColor(secondaryButtonColor.value)
  )
  const activeButtonColor = computed(
    () => Style.value.activeButtonColor || DEFAULT_ACTIVE
  )
  const activeButtonColorHover = computed(
    () =>
      Style.value.activeButtonColorHover || _hoverColor(activeButtonColor.value)
  )

  const themeVars = computed(() => {
    return {
      '--page-background': Style.value.pageBackground,
      '--button-radius': Style.value.buttonRadius,
      '--link-color': Style.value.linkColor || DEFAULT_PRIMARY,
      '--link-color-hover': primaryButtonColor.value,
      '--input-background-color': Style.value.inputBackgroundColor || '#F0F0F0',
      '--input-background-color-hover': _hoverColor(
        Style.value.inputBackgroundColor || '#F0F0F0'
      ),
      '--input-text-color': _selectTextColor(
        Style.value.inputBackgroundColor || '#FFFFFF'
      ),

      '--primary-button-color': primaryButtonColor.value,
      '--primary-button-color-hover': primaryButtonColorHover.value,
      '--primary-button-border-color':
        Style.value.primaryButtonBorderColor || primaryButtonColor.value,
      '--primary-button-border-color-hover':
        Style.value.primaryButtonBorderColorHover ||
        Style.value.primaryButtonColorHover ||
        _hoverColor(
          Style.value.primaryButtonBorderColor || primaryButtonColor.value
        ),

      '--secondary-button-color': secondaryButtonColor.value,
      '--secondary-button-color-hover':
        Style.value.secondaryButtonColorHover ||
        _hoverColor(secondaryButtonColor.value),
      '--secondary-button-border-color':
        Style.value.secondaryButtonBorderColor || secondaryButtonColor.value,
      '--secondary-button-border-color-hover':
        Style.value.secondaryButtonBorderColorHover ||
        Style.value.secondaryButtonColorHover ||
        _hoverColor(
          Style.value.secondaryButtonBorderColor || secondaryButtonColor.value
        ),

      '--active-button-color': activeButtonColor.value,
      '--active-button-color-hover':
        Style.value.activeButtonColorHover ||
        _hoverColor(activeButtonColor.value),
      '--active-button-border-color':
        Style.value.activeButtonBorderColor || activeButtonColor.value,
      '--active-button-border-color-hover':
        Style.value.activeButtonBorderColorHover ||
        Style.value.activeButtonColorHover ||
        _hoverColor(
          Style.value.activeButtonBorderColor || activeButtonColor.value
        ),

      '--tile-background-color': 'black',
      '--tile-border-color': primaryButtonColor.value || 'transparent',
      '--tile-radius': Style.value.tileRadius,

      '--primary-text': _selectTextColor(primaryButtonColor.value),
      '--primary-text-hover': _selectTextColor(primaryButtonColorHover.value),
      '--secondary-text': _selectTextColor(secondaryButtonColor.value),
      '--secondary-text-hover': _selectTextColor(
        secondaryButtonColorHover.value
      ),
      '--active-text': _selectTextColor(activeButtonColor.value),
      '--active-text-hover': _selectTextColor(activeButtonColorHover.value),
      '--light-text': '#FFFFFF',
      '--tile-text': '#FFFFFF',

      '--success': success.value,
      '--success-hover': _hoverColor(success.value),
      '--success-text': _selectTextColor(success.value),
      '--warning': warning.value,
      '--warning-hover': _hoverColor(warning.value),
      '--warning-text': _selectTextColor(warning.value),
      '--danger': danger.value,
      '--danger-hover': _hoverColor(danger.value),
      '--danger-text': _selectTextColor(danger.value),
    }
  })

  function watchTheme() {
    watchEffect(() => {
      const theme = getTheme(GAME_ID)
      _setThemable(theme.value)
    })

    watch(themeVars, _pushThemeVars, { immediate: true })
  }

  function _setThemable(theme) {
    // use game colors
    if (theme && !isObjEmpty(theme)) {
      updateTheme(theme)
    }
  }

  function updateTheme({
    danger = DEFAULT_DANGER,
    light = DEFAULT_LIGHT,
    primary = DEFAULT_PRIMARY,
    success = DEFAULT_SUCCESS,
    warning = DEFAULT_WARNING,
  } = {}) {
    if (!Style.value.primaryButtonColor) {
      setStyle('primaryButtonColor', primary)
    }
    updateLightColor(light)
    updateSuccessColor(success)
    updateWarningColor(warning)
    updateDangerColor(danger)
  }

  function updateLightColor(color) {
    _checkLightContrast(color)
    light.value = color
  }

  function updateSuccessColor(color) {
    success.value = color
  }

  function updateWarningColor(color) {
    warning.value = color
  }

  function updateDangerColor(color) {
    danger.value = color
  }

  function _selectTextColor(bgColor) {
    return Color(bgColor).isDark() ? DARK_TEXT : LIGHT_TEXT
  }

  function _hoverColor(color) {
    return Color(color).saturate(0.8).lighten(0.2).hex()
  }

  // the functions below check the incoming color contrast
  // if white or dark a different panel color is applied
  // to avoid full white or full black themes
  function _checkLightContrast(color) {
    lightTemp.value =
      Color().contrast(Color(color)) > WHITE_THRESHOLD ? WHITE_PANEL_REF : color
  }

  function _pushThemeVars() {
    for (let [key, value] of Object.entries(themeVars.value)) {
      document.documentElement.style.setProperty(key, value)
    }
  }

  return {
    watchTheme,
  }
}
