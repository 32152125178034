// default theme colors
export const DEFAULT_PRIMARY = '#FFFFFF'
export const DEFAULT_DARK = '#212945'
export const DEFAULT_LIGHT = '#EAEBF2'
export const DEFAULT_SECONDARY = '#000000'
export const DEFAULT_ACTIVE = '#E0E0E0'
export const DEFAULT_SUCCESS = '#7EEF8C'
export const DEFAULT_WARNING = '#FEF376'
export const DEFAULT_DANGER = '#DB5355'

// default theme colors
export const LIGHT_TEXT = '#0E101A'
export const DARK_TEXT = '#FFFFFF'
